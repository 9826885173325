import React from 'react'
import {ERORR_ALERT_OBJECT, WARNING_ALERT_OBJECT} from './AlertPopup'

export const handlingAfterFetch = async (fetch, success_handler, setAlertObject, isThankYou) => {
    try {
        const result = await fetch;
        if(result.status === 200){
            if(result.success) {
              if (success_handler) return success_handler(result);
            } else {
                setAlertObject && setAlertObject({...ERORR_ALERT_OBJECT, message: result.message});
                isThankYou && success_handler(result);
            }
        }else if (result.status === 401){
            // unautorized
            setAlertObject && setAlertObject({...WARNING_ALERT_OBJECT, severity:'warning', message: result.message})
        }else{
            setAlertObject && setAlertObject({...ERORR_ALERT_OBJECT, message: result.message})
        }
    } catch (error) {
        setAlertObject && setAlertObject({...ERORR_ALERT_OBJECT, message: `Unexpected error: ${error}`})
    }
}
