import { Grid } from '@mui/material';
import bckg from '../../assets/imgs/home_bckg_new.png'
import styled from '@emotion/styled';
import theme from '../CustomTheme';

export const HomeContainer = styled(Grid)(({ isRtl, windowWidth }) => ({
    backgroundImage: `url(${bckg})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    textAlign: isRtl ? 'right' : 'center',
    height: windowWidth > 1024 ? '100vh' : 'auto',
    paddingBlock: '120px 40px',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign:'center',
    minHeight: '100vh'
}));

export const newBtnStyles = (windowWidth, isRtl) => { return{
    background: theme.palette.primary.main,
    color: '#FFFFFF',
    padding: 'max(1.28vw, 15px) max(1.563vw, 20px)',
    minWidth: 'fit-content',
    width: isRtl 
    ?(windowWidth > 992 ? '21.563vw' : windowWidth > 576 ? '40vw' : '70vw')
    : (windowWidth > 992 ? '23vw' : windowWidth > 576 ? '45vw' : '75vw')   ,
    borderRadius: 'max(3.125vw, 40px)',
    fontSize:  'max(2.344vw, 24px)',
    lineHeight: '1',
    overflow: 'hidden',
    transition: 'all ease-out 0.7s',
    'span.title':{
        paddingInlineEnd: 'max(2.188vw, 30px)',
        zIndex: 2,
    },
    'span.icon':{
        top: '50%',
        transform: isRtl ?  'translateY(-50%)' : 'translateY(-50%) scale(-1, 1)',
        right: 'max(1.563vw, 20px)',
        boxShadow: '-3px -3px 21px 0px rgba(0, 0, 0, 0.25)',
        borderRadius: '50%',
        zIndex: 2,
        height: 'max(2.917vw, 40px)',
        width: 'max(2.917vw, 40px)',
        'svg':{
            width: '100%',
            height: '100%'
        }
    },
    '&::before':{
        content: '""',
        position: 'absolute',
        height: '100%',
        width: 0,
        top: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        background: '#263D4F',
        borderRadius: 'max(3.125vw, 40px)',
        opacity: 0,
        transition: 'opacity 0.2s, width 0.5s',
    },
    '&:hover':{
        background: '#37C4E8',
        '&::before':{
            width: '100%',
            opacity: 1
        },
        'span.icon': {
            'svg':{
                'ellipse':{
                    fill: '#37C4E8'
                }
            }
        }
    }
}};