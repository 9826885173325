import { Button, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useContext } from 'react'
import AppContext from '../Contexts/AppContext'
import bckg from '../assets/imgs/homepage_bckg.png';
import bckg_mobile from '../assets/imgs/homepage_bckg_mobile.png';
import logo_hever from '../assets/imgs/logo.png';
import logo_hever_eng from '../assets/imgs/Translations_Logo_eng.png';
import { max } from 'moment';
import { Link } from 'react-router-dom';

function NotFound() {
    const {windowWidth, isRtl} = useContext(AppContext);

    const container_styles = {
        height: '100vh',
        width: '100%',
    }
    
    const start_content_styles = {
        width: windowWidth > 1024 ? '74%' : '100%',
        backgroundImage: windowWidth > 1024 ? `url(${bckg})` : `url(${bckg_mobile})`,
        backgroundPosition:  windowWidth > 1024 ? `${isRtl ? 'left' : 'right'}` : 'top',
        backgroundRepeat: 'no-repeat',
        paddingInline: 'max(30px, 8vw)',
        backgroundSize: 'cover',
        paddingBlock: windowWidth > 1024 ? null : 'max(140px, 22vw) max(80px, 10vw)',
        gap: 'max(30px, 5vh)',
        '*': {
            color: '#fff !important',
        }

    }

    const typo_404 = {
        fontSize: 'max(70px, 15vw)',
        fontWeight: 900,
        fontFamily: "DeliciousHandrawn !important",
        letterSpacing: '3px'
    }

    const end_content_styles = {
        width: windowWidth > 1024 ? '26%' : '100%',
        paddingInlineStart: windowWidth > 1024 ? `${isRtl ? 'max(20px, 6.5vw)' : '0'}` : 'max(30px, 4vw)',
        paddingInlineEnd: windowWidth > 1024 ? `${isRtl ? '0' : 'max(20px, 6.5vw)'}`  : 'max(30px, 4vw)',
        paddingTop:  windowWidth > 1024 ? null : 'max(140px, 18vw)'
    }

    return (
        <Grid sx={container_styles} className={`${isRtl ? 'row-reverse' : 'row'}`} container>
            <Box sx={start_content_styles} className='flex column align-end justify-center'>
                <Typography component='p' variant='p' sx={typo_404}>404</Typography>
                <Typography component='h1' variant='h1'>
                    {isRtl ? "אופס! הדף לא נמצא" : "OOPS! Page not found"}
                </Typography>
                <Typography component='h2' variant='h2' sx={{marginBottom: 'max(40px, 7vh)'}}>
                    {isRtl ? "מצטערים, הדף שאתם מחפשים לא קיים" : "Sorry, the page you are looking for does not exist"}
                </Typography>
                <Link to="/">
                    <Button variant='contained'> 
                        {isRtl ? "חזרה לדף הבית" : "Go Home"}
                    </Button>
                </Link>
            </Box>
            <Box sx={end_content_styles} className='flex column justify-center align-center'>
                <Link to="/" className="logo" style={{textAlign:`${windowWidth <= 1024 ? 'center' : null}`}}><img src={isRtl ? logo_hever : logo_hever_eng} alt="Hever"/></Link>
            </Box>
        </Grid>
    )
}

export default NotFound