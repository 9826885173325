import { ThemeProvider } from '@emotion/react';
import { Box, createTheme, experimental_sx as sx } from '@mui/material';
import { useContext } from 'react';
import AppContext from '../Contexts/AppContext';
import theme from './CustomTheme';

const { palette } = createTheme();


const theme_rtl = createTheme({ ...theme, direction: 'rtl'});



export default theme_rtl;