import {
	Button,
	Divider,
	FormControl,
	FormGroup,
	Grid,
	OutlinedInput,
	Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useContext, useEffect, useState } from 'react';
import logo_hever from '../assets/imgs/logo.png';
import logo_hever_eng from '../assets/imgs/Translations_Logo_eng.png';
import AppContext from '../Contexts/AppContext';
import theme from '../Styles/CustomTheme';
import {
	abs_position,
	CustomContainer,
	MainContainer,
	margin_styles,
	padding_styles,
} from '../Styles/GeneralStyles';
import { useLocation, useParams } from 'react-router-dom';
import { handlingAfterFetch } from '../Helpers/Tools';
import {
	getAllLanguages,
	getAllUpsells,
	GetDocTypes,
	getFilesByCartId,
	getLanguagesForTranscription,
	getOrderById,
	getOrderFrameLink,
} from '../services/api';
import moment from 'moment';
import CheckIcon from '@mui/icons-material/Check';
import Loader from '../Helpers/Loader';
import VerificationInput from 'react-verification-input';
import { ERORR_ALERT_OBJECT } from '../Helpers/AlertPopup';

const block_style = {
	boxShadow:
		'0px 5px 5px -3px rgba(0, 0, 0, 0.08), 0px 8px 10px 1px rgba(0, 0, 0, 0.08), 0px 3px 14px 2px rgba(0, 0, 0, 0.08)',
	borderRadius: '10px',
	background: theme.palette.grey[50],
	p: 2,
	rowGap: '12px',
	overflowY: 'auto',
};

const sale_price_style = {
	position: 'relative',
	color: theme.palette.grey[500],
	textAlign: 'start',
	'&::after': {
		content: '""',
		width: '60px',
		height: '2px',
		background: 'gray',
		position: 'absolute',
		top: '50%',
		left: '-5px',
		transform: 'translateY(-50%)',
	},
};

function LinkPayment() {
	const { isRtl, setAlertObject, currentLanguage } = useContext(AppContext);
	const { user_id, order_id } = useParams();
	const [orderData, setOrderData] = useState({});
	const [clientType, setClientType] = useState(
		orderData?.User?.UserDetail?.companyId ? 'עסקי' : 'פרטי',
	);
	const [orderedService, setOrderedService] = useState('');
	const [transcriptionLanguage, setTranslationLanguage] = useState({});
	const [translationData, setTranslationData] = useState({});
	const [orderFiles, setOrderFiles] = useState([]);
	const [orderUpsells, setOrderUpsells] = useState([]);
	const [iFrameSrc, setIframeSrc] = useState('');
	const [accessToken, setAccessToken] = useState('');
	const [isAccessAllowed, setIsAccessAllowed] = useState(false);

	const handleGetTranslationData = async () => {
		const updated_obj = {};
		await handlingAfterFetch(
			getAllLanguages(),
			(result) => {
				const targetLangsArr =
					orderData?.OrderDetail?.targetLang.split(',');
				const sourceLang = result.data.find(
					(lang) => lang.id === +orderData?.OrderDetail?.sourceLang,
				);
				const targetLangs = result.data.filter((lang) =>
					targetLangsArr.includes('' + lang.id),
				);
				updated_obj.sourceLang = sourceLang;
				updated_obj.targetLangs = targetLangs;
			},
			setAlertObject,
		);
		await handlingAfterFetch(
			GetDocTypes(),
			(result) => {
				const docType = result.data.find(
					(doc) => doc.id === +orderData?.OrderDetail?.docType,
				);
				// setAllDocTypes(result.data);
				updated_obj.docType = docType;
			},
			setAlertObject,
		);
		setTranslationData(updated_obj);
	};

	const fetchCartFiles = async (cart_id) => {
		await handlingAfterFetch(
			getFilesByCartId(cart_id),
			(result) => {
				setOrderFiles(result.data);
			},
			setAlertObject,
		);
	};

	const fetchOrderUpsells = async () => {
		const upsells_arr = orderData?.OrderDetail?.upsells?.split(',');
		if (upsells_arr) {
			await handlingAfterFetch(
				getAllUpsells(),
				(result) => {
					const updated_arr = result.data.filter((item) =>
						upsells_arr.includes('' + item.id),
					);
					setOrderUpsells(updated_arr);
				},
				setAlertObject,
			);
		}
	};

	const handleGetTranscriptionLanguages = async () => {
		await handlingAfterFetch(
			getLanguagesForTranscription(),
			(result) => {
				const lang = result.data.find(
					(lang) => lang.id === orderData?.OrderDetail?.sourceLang,
				);
				setTranslationLanguage(lang);
			},
			setAlertObject,
		);
	};

	const handleGetOrderData = async () => {
		if (!order_id) return;
		await handlingAfterFetch(
			getOrderById(order_id),
			(result) => {
				if (result.success && result.data) {
					setOrderData(result.data);
					setOrderedService(
						result.data?.OrderDetail?.docType &&
							result.data?.OrderDetail?.docType !== null
							? 'תרגום'
							: 'תמלול',
					);
					fetchCartFiles(result.data?.cartId);
					fetchOrderUpsells();
				}
			},
			setAlertObject,
		);
	};

	const handleGetPaymentFrame = async () => {
		let custom_data = {
			orderId: order_id,
			userId: user_id,
			lang: currentLanguage.code,
		};

		await handlingAfterFetch(
			getOrderFrameLink(custom_data),
			(result) => {
				if (result.success && result.data) {
					setIframeSrc(result.data);
				}
			},
			setAlertObject,
		);
	};

	const handleVerifyCode = () => {
		if (
			accessToken.toLowerCase() ===
			orderData?.OrderDetail?.verifyCode.toLowerCase()
		) {
			setIsAccessAllowed(true);
		} else {
			let message = isRtl
				? 'הקוד שלך שגוי או שפג תוקפו. נסה שוב או צור קשר עם איש הקשר שלך'
				: 'Your code is either incorrect or expired. Try again or get in touch with your contact person.';
			setAlertObject({ ...ERORR_ALERT_OBJECT, message });
		}
	};

	useEffect(() => {
		handleGetOrderData();
	}, []);

	useEffect(() => {
		orderedService === 'תמלול' && handleGetTranscriptionLanguages();
		orderedService === 'תרגום' && handleGetTranslationData();
	}, [orderedService]);

	useEffect(() => {
		Object.keys(orderData).length > 0 && handleGetPaymentFrame();
	}, [orderFiles]);

	return (
		<CustomContainer className='container-home-user flex column justify-center align-center'>
			{isAccessAllowed && (
				<>
					{Object.keys(orderData).length > 0 &&
						orderFiles.length > 0 &&
						iFrameSrc.length > 0 && (
							<Box
								className='flex column align-center'
								sx={[
									MainContainer,
									padding_styles(`24px 2.7vw`),
									margin_styles('5vw 0 3vw'),
								]}
								rowGap='2vw'
							>
								<Grid
									className='justify-between align-center'
									container
								>
									<Box maxWidth={180}>
										<img
											src={
												isRtl
													? logo_hever
													: logo_hever_eng
											}
											alt='Hever'
										/>
									</Box>
									<Typography
										variant='h1'
										component='h1'
										sx={{ fontSize: 'max(25px, 2.4vw)' }}
									>
										{isRtl
											? 'פרטי הזמנה '
											: 'Order Details'}
										{`# ${orderData.id}`}
									</Typography>
								</Grid>
								<Divider
									sx={{
										background: theme.palette.primary.light,
									}}
									flexItem
								/>
								<Box className='flex column' width={'100%'}>
									<Box className='order_body flex justify-between'>
										<Box
											width={'30%'}
											className='flex column justify-between'
											sx={{ rowGap: 'max(22px, 1.8vw)' }}
										>
											<Box sx={block_style}>
												<Typography
													variant='h3'
													component='h3'
													mb={1}
												>{`פרטי משתמש`}</Typography>
												<Box
													mt={3}
													className='flex column'
													rowGap={'10px'}
												>
													{/* <Box className='flex align-center' columnGap='10px'>
                                                    <Typography variant='p' component='p' fontWeight={700}> {` סוג לקוח: `} </Typography>
                                                    <Typography variant='p' component='p'> {clientType}</Typography>
                                                </Box> */}
													{clientType === 'עסקי' && (
														<>
															<Box
																className='flex align-center'
																columnGap='10px'
															>
																<Typography
																	variant='p'
																	component='p'
																	fontWeight={
																		700
																	}
																>
																	{' '}
																	{` שם חברה: `}{' '}
																</Typography>
																<Typography
																	variant='p'
																	component='p'
																>
																	{' '}
																	{
																		orderData
																			?.User
																			?.UserDetail
																			?.companyName
																	}
																</Typography>
															</Box>
															<Box
																className='flex align-center'
																columnGap='10px'
															>
																<Typography
																	variant='p'
																	component='p'
																	fontWeight={
																		700
																	}
																>
																	{' '}
																	{` ח.פ. `}{' '}
																</Typography>
																<Typography
																	variant='p'
																	component='p'
																>
																	{' '}
																	{
																		orderData
																			?.User
																			?.UserDetail
																			?.companyId
																	}
																</Typography>
															</Box>
															<Box
																className='flex align-center'
																columnGap='10px'
															>
																<Typography
																	variant='p'
																	component='p'
																	fontWeight={
																		700
																	}
																>
																	{' '}
																	{` שם איש קשר: `}{' '}
																</Typography>
																<Typography
																	variant='p'
																	component='p'
																>
																	{' '}
																	{
																		orderData
																			?.User
																			?.UserDetail
																			?.fullName
																	}
																</Typography>
															</Box>
															<Box
																className='flex align-center'
																columnGap='10px'
															>
																<Typography
																	variant='p'
																	component='p'
																	fontWeight={
																		700
																	}
																>
																	{' '}
																	{` טלפון: `}{' '}
																</Typography>
																<Typography
																	variant='p'
																	component='p'
																>
																	{' '}
																	{
																		orderData
																			?.User
																			?.UserDetail
																			?.mobile
																	}
																</Typography>
															</Box>
															<Box
																className='flex align-center'
																columnGap='10px'
															>
																<Typography
																	variant='p'
																	component='p'
																	fontWeight={
																		700
																	}
																>
																	{' '}
																	{` מייל: `}{' '}
																</Typography>
																<Typography
																	variant='p'
																	component='p'
																>
																	{' '}
																	{
																		orderData
																			?.User
																			?.UserDetail
																			?.email
																	}
																</Typography>
															</Box>
														</>
													)}
													{clientType === 'פרטי' && (
														<>
															<Box
																className='flex align-center'
																columnGap='10px'
															>
																<Typography
																	variant='p'
																	component='p'
																	fontWeight={
																		700
																	}
																>
																	{' '}
																	{` שם מלא: `}{' '}
																</Typography>
																<Typography
																	variant='p'
																	component='p'
																>
																	{' '}
																	{
																		orderData
																			?.User
																			?.UserDetail
																			?.fullName
																	}
																</Typography>
															</Box>
															<Box
																className='flex align-center'
																columnGap='10px'
															>
																<Typography
																	variant='p'
																	component='p'
																	fontWeight={
																		700
																	}
																>
																	{' '}
																	{` ת.ז. `}{' '}
																</Typography>
																<Typography
																	variant='p'
																	component='p'
																>
																	{' '}
																	{
																		orderData
																			?.User
																			?.UserDetail
																			?.socialId
																	}
																</Typography>
															</Box>
															<Box
																className='flex align-center'
																columnGap='10px'
															>
																<Typography
																	variant='p'
																	component='p'
																	fontWeight={
																		700
																	}
																>
																	{' '}
																	{` טלפון: `}{' '}
																</Typography>
																<Typography
																	variant='p'
																	component='p'
																>
																	{' '}
																	{
																		orderData
																			?.User
																			?.UserDetail
																			?.mobile
																	}
																</Typography>
															</Box>
															<Box
																className='flex align-center'
																columnGap='10px'
															>
																<Typography
																	variant='p'
																	component='p'
																	fontWeight={
																		700
																	}
																>
																	{' '}
																	{` מייל: `}{' '}
																</Typography>
																<Typography
																	variant='p'
																	component='p'
																>
																	{' '}
																	{
																		orderData
																			?.User
																			?.UserDetail
																			?.email
																	}
																</Typography>
															</Box>
														</>
													)}
												</Box>
											</Box>
											<Box sx={block_style}>
												<Typography
													variant='h3'
													component='h3'
												>
													{' '}
													{` פרטים כללים `}{' '}
												</Typography>
												<Box
													mt={3}
													className='flex column'
													rowGap={'10px'}
												>
													<Box
														className='flex align-center'
														columnGap='10px'
													>
														<Typography
															variant='p'
															component='p'
															fontWeight={700}
														>
															{' '}
															{` תאריך הזמנה: `}{' '}
														</Typography>
														<Typography
															variant='p'
															component='p'
														>
															{' '}
															{moment(
																orderData?.createdAt,
															).format(
																'YYYY MMM DD',
															)}
														</Typography>
													</Box>
													<Box
														className='flex align-center'
														columnGap={
															'10px'
														}
													>
														<Typography
															variant='p'
															component='p'
															fontWeight={700}
														>
															{' '}
															{`תאריך הגשה: `}{' '}
														</Typography>
														<Typography
															variant='p'
															component='p'
														>
															{' '}
															{moment(
																orderData
																	?.OrderDetail
																	?.dueDate,
															).format(
																'YYYY MMM DD',
															)}{' '}
														</Typography>
													</Box>
													{/* <Box className='flex align-center' columnGap='10px'>
                                                    <Typography variant='p' component='p' fontWeight={700}> {` סטטוס הזמנה: `} </Typography>
                                                    <Typography variant='p' component='p'> {orderData?.status}</Typography>
                                                </Box> */}
												</Box>
											</Box>
											<Box sx={block_style}>
												{/* {orderData.OrderDetail.Coupon ? } */}
												<Typography
													variant='h3'
													component='h3'
												>
													{' '}
													{` עלות הזמנה `}{' '}
												</Typography>
												<Box mt={1}>
													{orderData.OrderDetail
														?.Coupon
														?.couponCode && (
														<>
															<Box
																className='flex align-center'
																columnGap='10px'
															>
																<Typography
																	variant='p_sm'
																	component='p'
																	fontWeight={
																		700
																	}
																>
																	{' '}
																	{`שימוש בקופון: `}{' '}
																</Typography>
																<Typography
																	variant='p'
																	component='span'
																	fontWeight={
																		800
																	}
																>
																	{' '}
																	{
																		orderData
																			.OrderDetail
																			?.Coupon
																			?.couponCode
																	}{' '}
																</Typography>
															</Box>
															<Box
																className='flex align-center'
																columnGap='10px'
															>
																<Typography
																	variant='p_sm'
																	component='p'
																	fontWeight={
																		700
																	}
																>
																	{' '}
																	{` לפני שימוש בקופון: `}{' '}
																</Typography>
																<Typography
																	width={
																		'100%'
																	}
																	variant='h3'
																	component='h3'
																	sx={{
																		...sale_price_style,
																		width: 'fit-content',
																	}}
																>
																	{`${orderData.OrderDetail?.subTotal} ₪`}
																</Typography>
															</Box>
														</>
													)}
												</Box>
												<Typography
													variant='h3'
													component='h3'
													color={
														theme.palette.success
															.dark
													}
												>
													{`סה״כ: ${orderData.OrderDetail?.total} ₪`}
												</Typography>
											</Box>
										</Box>
										<Box
											width={'65%'}
											sx={block_style}
											className='flex column'
										>
											<Typography
												variant='h3'
												component='h3'
												mb={1}
											>
												{' '}
												{`פרטי הזמנה`}{' '}
											</Typography>
											<Box
												className='flex align-center'
												columnGap={'10px'}
											>
												<Typography
													variant='p'
													component='p'
													fontWeight={700}
												>
													{' '}
													{` שירות: `}{' '}
												</Typography>
												<Typography
													variant='p'
													component='p'
													sx={{
														textDecoration:
															'underline',
													}}
												>
													{orderedService}
												</Typography>
											</Box>
											{orderedService === 'תמלול' && (
												<Box
													className='flex align-center'
													columnGap={'10px'}
												>
													<Typography
														variant='p'
														component='p'
														fontWeight={700}
													>
														{' '}
														{` שפת קבצים: `}{' '}
													</Typography>
													<Typography
														variant='p'
														component='p'
														sx={{
															textDecoration:
																'underline',
														}}
													>
														{
															transcriptionLanguage?.nameHe
														}
													</Typography>
												</Box>
											)}
											{orderedService === 'תרגום' && (
												<>
													<Box
														className='flex align-center'
														columnGap={'10px'}
													>
														<Typography
															variant='p'
															component='p'
															fontWeight={700}
														>
															{' '}
															{` שפת מקור: `}{' '}
														</Typography>
														<Typography
															variant='p'
															component='p'
															sx={{
																textDecoration:
																	'underline',
															}}
														>
															{
																translationData
																	?.sourceLang
																	?.nameHe
															}
														</Typography>
													</Box>
													<Box
														className='flex align-center'
														columnGap={'10px'}
													>
														<Typography
															variant='p'
															component='p'
															fontWeight={700}
														>
															{' '}
															{` שפות לתרגום: `}{' '}
														</Typography>
														<Typography
															variant='p'
															component='p'
															sx={{
																textDecoration:
																	'underline',
															}}
														>
															{translationData?.targetLangs?.map(
																(lang, key) => {
																	if (
																		key ===
																		translationData
																			.targetLangs
																			.length -
																			1
																	)
																		return (
																			<span>
																				{
																					lang.nameHe
																				}
																			</span>
																		);
																	return (
																		<span>
																			{`${lang.nameHe}, `}{' '}
																		</span>
																	);
																},
															)}
														</Typography>
													</Box>
													<Box
														className='flex align-center'
														columnGap={'10px'}
													>
														<Typography
															variant='p'
															component='p'
															fontWeight={700}
														>
															{' '}
															{` נושא מסמך: `}{' '}
														</Typography>
														<Typography
															variant='p'
															component='p'
															sx={{
																textDecoration:
																	'underline',
															}}
														>
															{
																translationData
																	?.docType
																	?.nameHe
															}
														</Typography>
													</Box>
												</>
											)}
											<Box>
												<Typography
													variant='p'
													component='p'
													fontWeight={700}
													mt={1.5}
												>
													{' '}
													קבצים:{' '}
												</Typography>
												<Box className='flex column'>
													{orderFiles.length > 0 &&
														orderFiles.map(
															(file, key) => {
																return (
																	<Box
																		key={
																			file.id
																		}
																		className='flex align-center justify-between'
																		sx={{
																			paddingBlock:
																				'5px',
																			borderTop: `1px solid ${theme.palette.grey[500]}`,
																			borderBottom:
																				key ===
																				orderFiles.length -
																					1
																					? `1px solid ${theme.palette.grey[500]}`
																					: null,
																		}}
																	>
																		<Typography
																			component='p'
																			variant='p_sm'
																			fontWeight={
																				600
																			}
																		>
																			{
																				file.fileName
																			}
																		</Typography>
																	</Box>
																);
															},
														)}
												</Box>
											</Box>
											<Box>
												{orderUpsells.length > 0 && (
													<Typography
														variant='p'
														component='p'
														fontWeight={700}
														mt={1.5}
													>
														{' '}
														תוספות:{' '}
													</Typography>
												)}
												<Box>
													{orderUpsells.length > 0 &&
														orderUpsells.map(
															(upsell, key) => {
																return (
																	<Box
																		key={
																			upsell.id
																		}
																		className='flex align-center'
																		sx={{
																			lineHeight: 1,
																			paddingBlock:
																				'8px',
																			borderTop: `1px solid ${theme.palette.grey[500]}`,
																			borderBottom:
																				key ===
																				orderUpsells.length -
																					1
																					? `1px solid ${theme.palette.grey[500]}`
																					: null,
																		}}
																	>
																		<Box>
																			<CheckIcon
																				color='success'
																				sx={{
																					mt: '5px',
																				}}
																			/>
																		</Box>
																		<Box
																			width={
																				'25%'
																			}
																		>
																			{
																				upsell.upsellName
																			}
																		</Box>
																		{/* {upsell.id ===
																			5 ||
																			(upsell.id ===
																				6 && (
																				//for duedate
																			))} */}
																	</Box>
																);
															},
														)}
												</Box>
											</Box>
											<Box>
												{orderedService === 'תמלול' &&
													orderData?.SpeakerDetails
														?.length > 0 && (
														<>
															<Typography
																variant='p'
																component='p'
																fontWeight={700}
															>
																{' '}
																{`פרטי דוברים:`}{' '}
															</Typography>
															<Box>
																{orderData?.SpeakerDetails?.map(
																	(
																		speaker,
																		key,
																	) => {
																		return (
																			<Box
																				className='flex align-start justify-between'
																				mt={
																					1
																				}
																			>
																				<Box
																					className='flex align-center'
																					width='30%'
																				>
																					<Typography
																						variant='p_sm'
																						component='p'
																						sx={{
																							textDecoration:
																								'underline',
																						}}
																						fontWeight={
																							700
																						}
																					>
																						{' '}
																						{` שם דובר: `}{' '}
																					</Typography>
																					<Typography
																						variant='p_sm'
																						component='p'
																						fontWeight={
																							600
																						}
																					>
																						{' '}
																						{
																							speaker.speakerName
																						}{' '}
																					</Typography>
																				</Box>
																				<Box
																					className='flex align-base'
																					width='60%'
																					gap={
																						'10px'
																					}
																				>
																					<Typography
																						variant='p_sm'
																						component='p'
																						sx={{
																							textDecoration:
																								'underline',
																						}}
																						fontWeight={
																							700
																						}
																					>
																						{' '}
																						{`תיאור:`}{' '}
																					</Typography>
																					<Typography
																						variant='p_sm'
																						component='p'
																						fontWeight={
																							600
																						}
																						textAlign='start'
																					>
																						{' '}
																						{
																							speaker.speakerDescription
																						}{' '}
																					</Typography>
																				</Box>
																			</Box>
																		);
																	},
																)}
															</Box>
														</>
													)}
											</Box>
											<Box>
												<Typography
													variant='p'
													component='p'
													fontWeight={700}
													mt={1}
												>
													{' '}
													{`הערות להזמנה:`}{' '}
												</Typography>
												<Typography
													variant='p_sm'
													component='p'
													fontWeight={500}
													textAlign='start'
												>
													{' '}
													{orderData?.OrderDetail
														?.clientComments
														? orderData?.OrderDetail
																?.clientComments
														: '-'}{' '}
												</Typography>
											</Box>
										</Box>
									</Box>
								</Box>
								<Box
									className='flex column align-center justify-center'
									sx={{
										width: '100%',
										height: 'min(750px, 83vh)',
									}}
								>
									<Box sx={{ width: '100%', height: '100%' }}>
										<iframe
											sandbox
											src={iFrameSrc}
											width={'100%'}
											height={'100%'}
											style={{ border: 'none' }}
											onLoad={(e) => {
												window.location.href =
													e.target.contentWindow.location.href;
											}}
										/>
									</Box>
								</Box>
							</Box>
					)}
					{Object.keys(orderData).length === 0 ||
						orderFiles.length === 0 ||
						(iFrameSrc.length === 0 && <Loader />)}
				</>
			)}
			{!isAccessAllowed && (
				<Box
					sx={[
						{ ...block_style },
						abs_position({
							top: '50%',
							left: '50%',
							transform: 'translate(-50%,-50%)',
						}),
					]}
				>
					<Typography variant='h2' component='h2' mb={3}>
						{isRtl
							? 'נא להזין את הקוד שקיבלת במייל'
							: 'Please enter the code you received in the email'}
					</Typography>
					<VerificationInput
						onChange={setAccessToken}
						inputField={{
							onChange: (e) => {
								setAccessToken(e.nativeEvent.target.value);
							},
							dir: 'ltr',
						}}
						length={8}
						validChars='A-Za-z0-9'
						removeDefaultStyles
						container={{
							className: 'container',
						}}
						characters={{
							className: 'characters',
						}}
						character={{
							className: 'character',
							classNameInactive: 'character--inactive',
							classNameSelected: 'character--selected',
						}}
					/>
					<Button
						variant='contained'
						sx={{ mt: 3 }}
						onClick={handleVerifyCode}
					>
						{' '}
						{isRtl ? 'אימות' : 'VERIFY'}{' '}
					</Button>
				</Box>
			)}
		</CustomContainer>
	);
}

export default LinkPayment;
