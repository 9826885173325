import { ThemeProvider } from '@emotion/react';
import { Box, createTheme, experimental_sx as sx } from '@mui/material';
import { useContext } from 'react';
import AppContext from '../Contexts/AppContext';

const { palette } = createTheme();

const theme = createTheme({
	direction: 'ltr',
	palette: {
		// orange
		primary: {
			main: '#F47440',
			light: '#FCD4C4',
			lightest: '#FEF1EC',
			dark: '#B75731',
			contrastText: '#233B4B',
		},
		pr_darker: {
			main: '#e39271',
			light: '#FCD4C4',
			lightest: '#FEF1EC',
			dark: '#B75731',
			contrastText: '#233B4B',
		},
		// turkiz
		secondary: {
			main: '#33C4E8',
			light: '#EBF9FD',
			dark: '#248ba5',
			darker: '#3396D1',
			contrastText: '#233B4B',
		},
		secondary_light: {
			main: '#C0EDF8',
			light: '#C0EDF8',
			dark: '#C0EDF8',
			contrastText: '#233B4B',
		},
		info: {
			main: '#3496d1',
			light: '#EBF5FB',
			dark: '#246992',
			contrastText: '#233B4B',
		},
		grey: {
			50: '#FCFCFC',
			100: '#F5F6F7',
			200: '#E0EFF8',
			300: '#cedde1',
			500: '#a5a5a5',
			800: '#233B4B',
			900: '#091E42',
		},
		disabled: {
			main: '#DFE2E6',
			dark: '#EBF5FB',
			contrastText: '#A6AEBB',
		},
		success: {
			main: '#36D133',
			light: '#b4e6b3',
			dark: '#2bb028',
		},
		error: {
			main: '#c22323',
			dark: '#851818',
		},
		alert_white: {
			main: '#fff',
			dark: '#fff',
			light: '#fff',
			contrastText: '#fff',
		},
	},
	typography: {
		fontFamily: ['Assistant', 'Arial', 'sans-serif'].join(','),
		h1: {
			fontSize: 'max(30px, 3.125vw)', //35px
			fontWeight: 800,
			color: '#233B4B',
		},
		h2: {
			fontSize: 'max(28px, 2.5vw)', //25px
			fontWeight: 800,
			color: '#233B4B',
		},
		h3: {
			fontSize: 'max(17px, 0.938vw)', //18px
			fontWeight: 800,
			color: '#233B4B',
		},
		h4: {
			fontSize: 'max(18px, 0.8vw)', //18px
			fontWeight: 700,
			color: '#FCFCFC',
		},
		p: {
			fontSize: 'max(16px, 0.833vw)',
			color: '#233B4B',
			textAlign: 'start',
		},
		p_sm: {
			fontSize: 'max(14px, 0.729vw)',
			color: '#233B4B',
		},
		button: {
			fontSize: 'max(16px, 1.1vw)',
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				containedPrimary: {
					color: '#233B4B',
					':hover': {
						backgroundColor: '#233B4B',
						color: '#FFF',
					},
				},
				outlined: {
					fontWeight: 700,
					borderRadius: '2vw',
					border: `2px solid #F47440`,
					color: '#233B4B',
					':hover': {
						backgroundColor: '#F47440',
						border: `2px solid #F47440`,
					},
				},
				contained: {
					borderRadius: '2vw',
					fontWeight: 700,
					color: '#FFF',
					textTransform: 'unset',
				},
				close_btn: {
					background: '#FCD4C4',
					color: '#233B4B',
					borderRadius: '50%',
					padding: 0,
					width: '24px',
					aspectRatio: '1',
					transitionDuration: '50ms',
					span: {
						margin: '0 !important',
					},
					':hover': {
						background: '#F47440',
						color: '#FFFFFF',
					},
				},
			},
		},
		MuiStepLabel: {
			styleOverrides: {
				fontWeight: 700,
			},
		},
		MuiDatePicker: {
			styleOverrides: {
				background: '#fff',
			},
		},
	},
});

export default theme;
