import React, { useContext } from 'react'
import Header from '../Components/Header'
import { Box, List, ListItem, ListItemText, Typography } from '@mui/material'
import { CustomContainer, MainContainer, margin_styles, padding_styles } from '../Styles/GeneralStyles'
import AppContext from '../Contexts/AppContext';
import theme from '../Styles/CustomTheme';

function AccessibilityStatement() {
    const {isRtl} = useContext(AppContext);

    return (
        <CustomContainer className='flex column align-center'>
            <Header isTransparent={false} />
            <Box
                className='flex column align-start'
                sx={[
                    MainContainer,
                    padding_styles(`34px 2vw`),
                    margin_styles('10vw 0 3vw'),
                ]}
                rowGap='2vw'
            >
                <Typography component={'h1'} variant={'h1'} textAlign={'center'} alignSelf={'center'}>
                    {isRtl ? 'הצהרת נגישות' : 'Accessibility Statement'}
                </Typography>
                {isRtl ? 
                    <>
                        <Typography component={'p'} variant={'p'}>
                        <b>חבר תרגומים בע"מ</b>, אחראית על הקמת והפעלת אתר <a href="https://pq.hevertranslations.co.il">https://pq.hevertranslations.co.il</a>. אנו רואים חשיבות רבה במתן שירות שוויוני לכלל האזרחים ובשיפור השירות הניתן לאזרחים עם מוגבלות.
                        <br/>
                        אנו משקיעים משאבים רבים בהנגשת האתר והנכסים הדיגיטליים שלנו על מנת להפוך את שירותי החברה לזמינים יותר עבור אנשים עם מוגבלות.
                        </Typography>
                        <Typography component={'p'} variant={'p'}>
                        במדינת ישראל כ-20 אחוזים מקרב האוכלוסייה הינם אנשים עם מוגבלות הזקוקים לנגישות דיגיטלית, על מנת לצרוך מידע ושירותים כללים.
                        <br/>
                        הנגשת האתר של <b>חבר תרגומים בע"מ</b>, נועדה להפוך אותו לזמין, ידידותי ונוח יותר לשימוש עבור אוכלוסיות עם צרכים מיוחדים, הנובעים בין היתר ממוגבלויות מוטוריות שונות,
                        לקויות קוגניטיביות, קוצר רואי, עיוורון או עיוורון צבעים, לקויות שמיעה וכן אוכלוסייה הנמנית על בני הגיל השלישי.
                        <br/>
                        הנגשת אתר זה בוצעה על ידי חברת הנגשת האתרים "Vee הנגשת אתרים".
                        </Typography>
                        <Typography component={'h2'} variant={'h2'}>רמת הנגישות באתר - AA</Typography>
                        <Typography component={'p'} variant={'p'}>חברת "Vee", התאימה את נגישות האתר לדפדפנים הנפוצים ולשימוש בטלפון הסלולרי ככל הניתן, והשתמשה בבדיקותיה בקוראי מסך מסוג Jaws ו- NVDA.</Typography>
                        <Typography component={'p'} variant={'p'}>
                        מקפידה על עמידה בדרישות תקנות שוויון זכויות לאנשים עם מוגבלות 5568 התשע"ג 2013 ברמת AA. וכן, מיישמת את המלצות מסמך WCAG2.2 מאת ארגון W3C.
                        </Typography>
                        <Typography component={'p'} variant={'p'}>
                        בעברית: <a href="https://www.isoc.org.il/files/w3c-wai/guidelines.html" target="_blank">הנחיות לנגישות תכנים באינטרנט</a><br/>באנגלית: <a href="https://www.w3.org/TR/WCAG20/" target="_blank">Web Content Accessibility Guidelines (WCAG) 2.0</a>
                        </Typography>
                        <Typography component={'p'} variant={'p'}>
                        הנגשת האתר בוצעה בהתאם <a href="https://www.gov.il/he/pages/accessibility_of_applications_in_web_browsers" target="_blank">להנחיות רשות התקשוב להנגשת יישומים בדפדפני אינטרנט</a>.
                        </Typography>
                        <Typography component={'h2'} variant={'h2'}>כיצד עוברים למצב נגיש?</Typography>
                        <Typography component={'p'} variant={'p'}>
                        באתר מוצב אייקון נגישות (בד"כ בדפנות האתר). לחיצה על האייקון מאפשרת פתיחת של תפריט הנגישות. לאחר בחירת הפונקציה המתאימה בתפריט יש להמתין לטעינת הדף ולשינוי הרצוי בתצוגה (במידת הצורך).
                        </Typography>
                        <Typography component={'p'} variant={'p'}>
                        במידה ומעוניינים לבטל את הפעולה, יש ללחוץ על הפונקציה בתפריט פעם שניה. בכל מצב, ניתן לאפס הגדרות נגישות.
                        </Typography>
                        <Typography component={'p'} variant={'p'}>
                        התוכנה פועלת בדפדפנים הפופולריים: Chrome, Firefox, Safari, Opera בכפוף (תנאי יצרן) הגלישה במצב נגישות מומלצת בדפדפן כרום.
                        </Typography>
                        <Typography component={'p'} variant={'p'}>
                        האתר מספק מבנה סמנטי עבור טכנולוגיות מסייעות ותמיכה בדפוס השימוש המקובל להפעלה עם מקלדת בעזרת מקשי החיצים, Enter ו- Esc ליציאה מתפריטים וחלונות.
                        </Typography>
                        <Typography component={'p'} variant={'p'}>
                        לצורך קבלת חווית גלישה מיטבית עם תוכנת הקראת מסך, אנו ממליצים לשימוש בתוכנת  NVDA העדכנית ביותר.
                        </Typography>
                        <Typography component={'h2'} variant={'h2'}>תיקונים והתאמות שבוצעו באתר:</Typography>
                        <Typography component={'p'} variant={'p'}>
                        התאמה לקורא מסך - התאמת האתר עבור טכנולוגיות מסייעות כגון NVDA , JAWS<br/>
                        אמצעי הניווט באתר פשוטים וברורים.<br/>
                        תכני האתר כתובים באופן ברור, מסודר והיררכי.<br/>
                        האתר מותאם לצפייה בדפדפנים מודרניים.<br/>
                        התאמת האתר לתצוגה תואמת מגוון מסכים ורזולוציות.<br/>
                        כל הדפים באתר בעלי מבנה קבוע (1H/2H/3H וכו').<br/>
                        לכל התמונות באתר יש הסבר טקסטואלי חלופי (alt).<br/>
                        </Typography>
                        <Typography component={'h2'} variant={'h2'}>פונקציונליות תוכנת נגישות:</Typography>
                        <Typography component={'p'} variant={'p'}>
                        התאמה לקורא מסך - התאמת האתר עבור טכנולוגיות מסייעות כגון NVDA , JAWS<br/>
                        עצירת הבהובים - עצירת אלמנטים נעים וחסימת אנימציות<br/>
                        דילוג ישיר לתוכן - דילוג על התפריט הראשי ישירות אל התוכן.<br/>
                        התאמה לניווט מקלדת.<br/>
                        הגדלה / הקטנה של טקסט.<br/>
                        ריווח בין אותיות / מילים / שורות.<br/>
                        ניגודיות וצבע - גבוהה, הפוכה, שחור לבן.<br/>
                        גופן קריא.<br/>
                        הדגשת קישורים.<br/>
                        מדריך קריאה.<br/>
                        שינוי אייקון סמן עכבר.<br/>
                        תיאור לתמונות.<br/>
                        </Typography>
                        <Typography component={'h2'} variant={'h2'}>החרגות</Typography>
                        <Typography component={'p'} variant={'p'}>
                        חשוב לציין, כי למרות מאמצינו להנגיש את כלל הדפים והאלמנטים באתר, ייתכן שיתגלו חלקים או יכולות שלא הונגשו כראוי או שטרם הונגשו.
                        </Typography>
                        <Typography component={'p'} variant={'p'}>
                        אנו פועלים לשפר את נגישות האתר שלנו כל העת, כחלק ממחויבותנו לאפשר לכלל האוכלוסייה להשתמש בו, כולל אנשים עם מוגבלות.
                        </Typography>
                        <Typography component={'h2'} variant={'h2'} marginTop={'3vh'}>יצירת קשר בנושא נגישות</Typography>
                        <Typography component={'p'} variant={'p'}>
                            <b>במידה ונתקלתם בבעיה בנושא נגישות באתר, נשמח לקבל הערות ובקשות באמצעות פנייה לרכז הנגישות שלנו:</b>
                            על מנת שנוכל לטפל בבעיה בדרך הטובה ביותר, אנו ממליצים מאוד לצרף פרטים מלאים ככל שניתן:
                        </Typography>
                        <List sx={{ listStyleType: 'disc', paddingInlineStart:'20px', marginTop: '-2vw'}}>
                            <ListItem sx={{ display: 'list-item', padding: '0' }}>
                                <ListItemText primary="תיאור הבעיה." />
                            </ListItem>
                            <ListItem sx={{ display: 'list-item', padding: '0' }}>
                                <ListItemText primary="הפעולה שניסיתם לבצע." />
                            </ListItem>
                            <ListItem sx={{ display: 'list-item', padding: '0' }}>
                                <ListItemText primary="קישור לדף שבו גלשתם." />
                            </ListItem>
                            <ListItem sx={{ display: 'list-item', padding: '0' }}>
                                <ListItemText primary="סוג הדפדפן וגרסתו." />
                            </ListItem>
                            <ListItem sx={{ display: 'list-item', padding: '0' }}>
                                <ListItemText primary="מערכת הפעלה." />
                            </ListItem>
                            <ListItem sx={{ display: 'list-item', padding: '0' }}>
                                <ListItemText primary="סוג הטכנולוגיה המסייעת (במידה והשתמשתם)." />
                            </ListItem>
                        </List>
                        <Typography component={'p'} variant={'p'}>
                        <b>חבר תרגומים בע"מ</b> תעשה ככל יכולה על מנת להנגיש את האתר בצורה המיטבית ולענות לפניות בצורה המקצועית והמהירה ביותר.
                        </Typography>
                        <Typography component={'p'} variant={'p'}>
                        רכז נגישות:<br/>
                        איילת סבן<br/>
                        <a href="tel:+972523859993">0523859993</a><br/>
                        <a href="mailto:ayelets@hevertranslations.com">ayelets@hevertranslations.com</a><br/>
                        תאריך עדכון הצהרת נגישות 08-07-2024<br/>
                        </Typography>
                    </> 
                    : 
                    <>
                        <Typography component={'p'} variant={'p'}>
                        <b>חבר תרגומים בע"מ</b>, operating and managing the <a href="https://pq.hevertranslations.co.il">https://pq.hevertranslations.co.il</a>, is committed to ensuring the accessibility of its digital services to all citizens, with a special emphasis on improving access for individuals with disabilities.
                        </Typography>
                        <Typography component={'p'} variant={'p'}>
                        Significant resources have been allocated to guarantee the accessibility of our website, aiming to enhance the experience for individuals with diverse needs, including but not limited to motor disabilities, cognitive impairments, myopia, blindness or color blindness, hearing impairments, and the elderly population.
                        </Typography>
                        <Typography component={'p'} variant={'p'}>
                        The implementation of website accessibility has been carried out by "VEE - Website Accessibility." Our website has been optimized for accessibility at level AA, adhering to the guidelines established by the Equal Rights for Persons with Disabilities Regulations WCAG2.2.
                        </Typography>
                        <Typography component={'p'} variant={'p'}>
                        Vee Company, the provider of website accessibility solutions, has ensured compatibility with popular web browsers and mobile devices. Extensive testing has been conducted using Jaws and NVDA screen readers. Additionally, we have implemented WCAG2.2 recommendations by the W3C organization to further enhance accessibility standards.
                        </Typography>
                        <Typography component={'p'} variant={'p'}>
                        Our website facilitates a straightforward process to switch to accessible mode. An accessibility icon is prominently placed on each webpage. Clicking on the icon opens the accessibility menu, allowing users to select desired accessibility functions. Please allow the page to load after making your selection to ensure the appropriate changes are applied.
                        </Typography>
                        <Typography component={'p'} variant={'p'}>
                        To revert the changes, simply click on the corresponding function in the menu again. Resetting the accessibility settings is also an option. The accessibility software is compatible with popular web browsers, such as Chrome, Firefox, Safari, and Opera.
                        </Typography>
                        <Typography component={'p'} variant={'p'}>
                        For an optimal experience with screen reader software, we recommend using the latest version of NVDA. The website's design includes a semantic structure supporting assistive technologies and adheres to accepted usage patterns for keyboard navigation.
                        </Typography>
                        <Typography component={'p'} variant={'p'}>
                        Several improvements have been made to enhance accessibility on the site, including adaptation for screen readers, clear and intuitive navigation, organized content presentation, optimization for modern web browsers, compatibility with various screen sizes, and consistent structure across all pages. Alt text has been included for all images.
                        </Typography>
                        <Typography component={'p'} variant={'p'}>
                        The accessibility software provides essential functionalities, including adaptation for screen readers, flickering prevention, direct content access, keyboard navigation adjustment, text size adjustment, enhanced spacing, contrast and color options, legible font selection, highlighting links, reading guide, customizable mouse cursor, and image descriptions.
                        </Typography>
                        <Typography component={'h2'} variant={'h4'} color={theme.palette.grey[800]}>Exclusions:</Typography>
                        <Typography component={'p'} variant={'p'}>
                        While diligent efforts have been made to ensure the accessibility of all pages and elements, it is acknowledged that there may still be instances where certain parts or functionalities are not fully accessible. Continuous efforts are underway to enhance accessibility and ensure inclusivity for all individuals, including those with disabilities.
                        </Typography>
                        <Typography component={'p'} variant={'p'}>
                        If you encounter accessibility issues on the website, please contact us with complete details, including a problem description, the action you attempted, the link to the page you browsed, browser type and version, operating system, and the type of assistive technology used.
                        </Typography>
                        <Typography component={'p'} variant={'p'}>
                        <b>חבר תרגומים בע"מ</b> is committed to addressing accessibility concerns promptly and professionally.
                        </Typography>
                        <Typography component={'h2'} variant={'h4'} color={theme.palette.grey[800]}>Contact us at:</Typography>
                        <Typography component={'p'} variant={'p'}>
                        Ayelet Saban<br/>
                        <a href="tel:+972523859993">0523859993</a><br/>
                        <a href="mailto:ayelets@hevertranslations.com">ayelets@hevertranslations.com</a><br/>
                        Date of Accessibility Statement Update: 08-07-2024<br/>

                        </Typography>
                    </>
                }
            </Box>
        </CustomContainer>
    )
}

export default AccessibilityStatement