import React from 'react'
import { margin_styles } from '../Styles/GeneralStyles'

function Loader() {
    return (
        <div className="loader flex wrap justify-center" style={margin_styles(`max(30px, 2vw)`)}>
            <span style={{background: '#34c3e7'}}>&nbsp;</span>
            <span style={{background: '#f4743f', animationDelay: '0.2s'}}>&nbsp;</span>
            <span style={{background: '#34c3e7', animationDelay: '0.5s'}}>&nbsp;</span>
            <span style={{background: '#233b4b', animationDelay: '0.7s'}}>&nbsp;</span>
        </div>
    )
}

export default Loader