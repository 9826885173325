import { Axios } from 'axios';
import {
	fetchGet,
	fetchPost,
	fetchPut,
	fetchDelete,
	SERVER_ENDPOINT,
	fetchGetExternalUrl,
	fetchPostFiles,
} from './index';
import moment from 'moment';

export const userSignIn = async (user_data) => {
	return await fetchPost(
		`${SERVER_ENDPOINT}/api/users/userLogin`,
		user_data,
		'application/json',
		'User login',
	);
};

export const getSystemLanguages = async () => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/systemLang/getSystemLanguages`,
		'Get System Languages',
	);
};

export const getPages = async (data) => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/pages/getPages?lang=${data}`,
		'Get Pages',
	);
};

export const getAllLanguages = async () => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/languages/getAllLanguages`,
		'Get Translatable Languages',
	);
};

export const fetchVerifiedUser = async () => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/users/userVerify`,
		'Verifying user session',
	);
};

export const getServices = async (lang_id) => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/services/getService?lang=${lang_id}`,
		'Get Site Services',
	);
};

export const editService = async (data) => {
	return await fetchPut(`${SERVER_ENDPOINT}/api/services/editService`, data);
};

export const createCart = async (serviceId) => {
	return await fetchPost(`${SERVER_ENDPOINT}/api/carts/newCart`, {
		serviceId,
	});
};

export const uploadFiles = async (data, config) => {
	return await fetchPostFiles(
		`${SERVER_ENDPOINT}/api/files/addFile`,
		data,
		config,
	);
};
export const processDocAbbyy = async (fileId) => {
	return await fetchPut(
		`${SERVER_ENDPOINT}/api/files/processDocAbbyy?fileId=${fileId}`,
	);
};

export const getCartById = async (cart_id) => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/carts/getCartById?id=${cart_id}`,
		'Add New File',
	);
};

export const getFilesByCartId = async (cart_id) => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/files/getFilesByCart?cartId=${cart_id}`,
		'Get Files',
	);
};

export const deleteFileById = async (fileId) => {
	return await fetchDelete(
		`${SERVER_ENDPOINT}/api/files/deleteFile?id=${fileId}`,
	);
};

export const getHebCalendar = async () => {
	const today = moment().format('YYYY-MM-DD');
	const next_month = moment().add(3, 'months').format('YYYY-MM-DD');
	return await fetchGetExternalUrl(
		`https://www.hebcal.com/hebcal?v=1&cfg=json&maj=on&min=on&mod=on&nx=on&year=now&ss=on&mf=on&c=on&geo=geoname&geonameid=3448439&M=on&s=on&i=on&start=${today}&end=${next_month}`,
		'Get Heb Calendar',
	);
};

export const GetDocTypes = async () => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/docTypes/getAllDocTypes`,
		'Get Doc Types',
	);
};

export const getPricePerWord = async ({ source, target, docType }) => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/prices/getPricePerWord?source=${source}&target=${target}&docType=${docType}`,
		'Get Price Per Word',
	);
};

export const getAllUpsells = async () => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/upsells/getAllUpsells`,
		'Get All Upsells',
	);
};

export const getUpsells = async (lang_id) => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/upsells/getUpsells?lang=${lang_id}`,
		'Get Upsells',
	);
};

export const addUpsell = async (data) => {
	return await fetchPost(`${SERVER_ENDPOINT}/api/upsells/AddUpsell`, data);
};

export const updateUpsell = async (data) => {
	return await fetchPut(`${SERVER_ENDPOINT}/api/upsells/updateUpsell`, data);
};

export const deleteUpsell = async (id) => {
	return await fetchDelete(
		`${SERVER_ENDPOINT}/api/upsells/deleteUpsell?id=${id}`,
	);
};

export const getLanguagesForTranscription = async () => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/languages/getLanguagesForTranscription`,
		'Get Languages For Transcription',
	);
};

export const getPricePerMin = async (data) => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/prices/getPricePerMin?source=${data.source}&target=${data.target}`,
		'Get Price Per Min',
	);
};

export const createNewCoupon = async (data) => {
	return await fetchPost(
		`${SERVER_ENDPOINT}/api/coupons/createNewCoupon`,
		data,
	);
};

export const getAllCoupons = async () => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/coupons/getAllCoupons`,
		'Get Coupons',
	);
};

export const getCouponByCode = async (code) => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/coupons/getCouponByCode?code=${code}`,
		'Get Coupon by Code',
	);
};

export const editCoupon = async (data) => {
	return await fetchPut(
		`${SERVER_ENDPOINT}/api/coupons/editCoupon?id=${data.id}`,
		data,
	);
};

export const deleteCoupon = async (id) => {
	return await fetchDelete(
		`${SERVER_ENDPOINT}/api/coupons/deleteCoupon?id=${id}`,
	);
};

export const createField = async (data) => {
	return await fetchPost(`${SERVER_ENDPOINT}/api/fields/addField`, data);
};

export const getField = async (fieldName, pageId) => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/fields/getField?fieldName=${fieldName}&pageId=${pageId}`,
		'Get Field',
	);
};

export const getAllFields = async (fieldname, pageId) => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/fields/getAllFields`,
		'Get All Fields',
	);
};

export const editField = async (data) => {
	return await fetchPut(
		`${SERVER_ENDPOINT}/api/fields/updateField?id=${data.id}`,
		data,
	);
};

export const getSystemMessagesByLang = async (lang) => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/fields/getSystemMessagesPerLang?lang=${lang}`,
		'getSystemMessagesByLang',
	);
};

export const createOrder = async (data) => {
	return await fetchPost(`${SERVER_ENDPOINT}/api/orders/createOrder`, data);
};

export const getOrders = async () => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/orders/getAllOrders`,
		'get Orders',
	);
};

export const getOrderById = async (orderId) => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/orders/getOrderById?id=${orderId}`,
		'get Order by Id',
	);
};

export const getOrderFrameLink = async (data) => {
	return await fetchPost(
		`${SERVER_ENDPOINT}/api/orders/paymentWithLink`,
		data,
	);
};
export const downloadOrdersList = async () => {
	return await fetchGet(`${SERVER_ENDPOINT}/api/orders/downloadOrdersList`);
};

export const getAllSystemSettings = async () => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/settings/getAllSystemSettings`,
		'get All System Settings',
	);
};

export const getSystemSettingsByName = async (name) => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/settings/getSystemSettingByName?name=${name}`,
		'get System Setting By Name',
	);
};

export const EditSystemSetting = async (data) => {
	return await fetchPut(`${SERVER_ENDPOINT}/api/settings/editSetting`, data);
};

export const getAllCompanies = async () => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/companies/getAllCompanies`,
		'get companies',
	);
};

export const getCompanyNumber = async (number) => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/companies/getCompanyNumber?number=${number}`,
		'get Company Number',
	);
};

export const uploadCompanyList = async (data) => {
	return await fetchPost(
		`${SERVER_ENDPOINT}/api/companies/uploadCompanyList`,
		data,
	);
};

export const downloadCompanyList = async () => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/companies/downloadCompanyList`,
		'get existing excel file',
	);
};

export const updatePriceTranslation = async (data) => {
	delete data.createdAt;
	delete data.updatedAt;

	return await fetchPut(
		`${SERVER_ENDPOINT}/api/prices/updatePriceTranslation`,
		data,
	);
};

export const getTranscriptionDeadline = async (serviceId, numMinutes) => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/deadlines/getDeadlineTranscription?service=${serviceId}&unit=${numMinutes}`,
		'Get Transcription Deadline',
	);
};

export const getTranslationDeadline = async (serviceId, docTypeId) => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/deadlines/getDeadlineTranslation?service=${serviceId}&doc=${docTypeId}`,
		'Get Translation Deadline',
	);
};

export const getDeadlinesByServiceId = async (service) => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/deadlines/getDeadlinesByService?service=${service}`,
		'Get Deadlines By Service Id',
	);
};

export const updateCartLangData = async (data) => {
	return await fetchPut(
		`${SERVER_ENDPOINT}/api/carts/saveCartLangData`,
		data,
	);
};

export const AuthenticateAtDropbox = async () => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/dropbox/auth`,
		'AuthenticateAtDropbox',
	);
};

export const CheckDropboxStatus = async () => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/dropbox/checkStatus`,
		'Check Dropbox Status',
	);
};

export const getFailedOrders = async () => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/orders/getFailedDropboxOrders`,
		'get Failed Dropbox Orders',
	);
};

export const SendFilesToDropbox = async (order_id) => {
	return await fetchPost(
		`${SERVER_ENDPOINT}/api/files/sendFilesToDropbox?orderId=${order_id}`,
	);
};

export const SendOrderCompletionHandling = async (data) => {
	return await fetchPut(
		`${SERVER_ENDPOINT}/api/orders/orderCompletionHandling`,
		data,
	);
};

export const EditTranslationDeadline = async (data) => {
	return await fetchPut(
		`${SERVER_ENDPOINT}/api/deadlines/editDeadlineTranslation`,
		data,
	);
};

export const EditTranscriptionDeadline = async (data) => {
	return await fetchPut(
		`${SERVER_ENDPOINT}/api/deadlines/editDeadlineTranscription`,
		data,
	);
};

export const EditOrderStatus = async (data) => {
	return await fetchPut(
		`${SERVER_ENDPOINT}/api/orders/editOrderStatus`,
		data,
	);
};
export const EditPaymentStatus = async (data) => {
	return await fetchPut(
		`${SERVER_ENDPOINT}/api/orders/editPaymentStatus`,
		data,
	);
};

export const EditCartService = async (data) => {
	return await fetchPut(
		`${SERVER_ENDPOINT}/api/carts/handleAdjustServiceToLang`,
		data,
	);
};
//editOrderStatus
