import { Box, Button } from '@mui/material';
import { useContext } from 'react';
import AppContext from '../Contexts/AppContext';
import theme from '../Styles/CustomTheme';
import { ReactComponent as ArrowIcon } from '../assets/imgs/btn_arrow_thin.svg';
import { newBtnStyles } from '../Styles/2024_rebranding/HomePage';

const floatingStyles = (windowWidth, isRtl) => ({
    ...newBtnStyles(windowWidth, isRtl),
    fontSize: 'max(1.042vw, 16px)',
    background: '#297AA9 !important',
    padding: 'max(0.6vw, 12px) max(1.146vw, 18px)',
    width: 'fit-content',
    gap: 'max(0.625vw, 10px)',
    'span.icon':{
        ...newBtnStyles(windowWidth, isRtl)['span.icon'],
        position: 'relative',
        height: 'max(2.083vw, 30px)',
        width: 'max(2.083vw, 30px)',
        right: 'unset',
        top: 'unset',
        transform: isRtl ? 'unset' : 'scale(-1,1)',
    },
	'span.title':{
        ...newBtnStyles(windowWidth)['span.title'],
        paddingInlineEnd: '0',
    },
});

const FloatingContactUs = () => {
	const { isRtl, windowWidth } = useContext(AppContext);
	return (
		<Box className="fixed" sx={{
            bottom: '5.234vh',
            right: '4.063vw',
            zIndex: 5}}
        >
			<Button
				href={'https://www.hevertranslations.co.il/for-quote/'}
				target='_blank'
				rel={'noreferrer'}
				className='flex align-center'
                sx={() => floatingStyles(windowWidth, isRtl)}
			>
                <span className="title">
				    {windowWidth > 768 && (isRtl ? 'לא מצאת את מה שחיפשת?' : 'Contact Us')}
                </span>
                <span className="icon flex absolute">
                    <ArrowIcon/>
                </span>
			</Button>
		</Box>
	);
};

export default FloatingContactUs;
